import { Officers } from "@app/core/inspections/[id]/model";
import {
  permitOptionOfficer,
  permitOptionPlacement,
  permitOptionPurpose,
} from "@app/products/local-laws/[id]/components/general/components/form-element/components/dates-section/config";
import {
  IHandlerEventInitialData,
  Permit,
  PermitRegisterHandlerRequest,
  PermitRegisterLovs,
  PermitRegisterUIControl,
  PermitUpdateTriggers,
  Placement,
  Purpose,
  Svc_FormAction_PermitRegister,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { isFieldVisible } from "@app/products/local-laws/[id]/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { KeywordLite } from "@common/models/keyword";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

interface IDateSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
const nameOfKeyword = nameOfFactory<KeywordLite>();

export const PermitDatesSection = observer(
  ({ formRenderProps }: IDateSectionProps) => {
    //#region STORE ========/
    const { dataForms } = useFlexibleFormStore();
    const uiControlStore =
      dataForms?.GeneralUIControl as PermitRegisterUIControl;
    const permitLOVsStore = dataForms?.GeneralFormLovs as PermitRegisterLovs;
    const { settings } = useCommonCoreStore();
    //#endregion STORE =====/

    //#region SETTINGS ========/
    const isShowPermitEffectiveDates = getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_ShowPermitEffectiveDates]
    );
    const isUsePermitEventName = getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_UsePermitEventName]
    );
    //#endregion SETTINGS =====/

    const { valueGetter, onChange } = formRenderProps;
    const getValue = (name: keyof Permit) => valueGetter(getNameOf(name));
    const permitObj = valueGetter(nameOfSvcPermit("Permit"));

    //#region HANDLER ========/
    const pickOfficerHandler = (officer: Officers) => {
      if (officer) {
        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickOfficer,
          Permit: permitObj,
          PermitRegisterArgs: officer?.ID ? [officer?.ID] : null,
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select officer failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Officer"), { value: null });
        onChange(getNameOf("Officer_ID"), { value: 0 });
      }
    };

    const pickPlacementHandler = (placement: KeywordLite[]) => {
      if (placement && placement?.length > 0) {
        let placementIDs: number[] | null = null;
        placementIDs = placement?.map(
          (placementItem: KeywordLite) => placementItem.Keyword_ID
        );

        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickPlacement,
          Permit: permitObj,
          PermitRegisterArgs: placementIDs,
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select placement failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Placements"), { value: [] });
        onChange(getNameOf("Placement_Namelist"), { value: [] });
        const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];
        if (!saveTriggers.includes(PermitUpdateTriggers.UpdatePlacements)) {
          onChange(getNameOf("_SaveTriggers"), {
            value: [...saveTriggers, PermitUpdateTriggers.UpdatePlacements],
          });
        }
      }
    };

    const pickPurposeHandler = (purpose: KeywordLite[]) => {
      if (purpose && purpose?.length > 0) {
        let purposeIDs: number[] | null = null;
        if (purpose && purpose.length > 0) {
          purposeIDs = purpose?.map(
            (purposeItem: KeywordLite) => purposeItem.Keyword_ID
          );
        }

        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickPurpose,
          Permit: permitObj,
          PermitRegisterArgs: purposeIDs,
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select purpose failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Purposes"), { value: [] });
        onChange(getNameOf("Purpose_Namelist"), { value: [] });
        const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];
        if (!saveTriggers.includes(PermitUpdateTriggers.UpdatePurposes)) {
          onChange(getNameOf("_SaveTriggers"), {
            value: [...saveTriggers, PermitUpdateTriggers.UpdatePurposes],
          });
        }
      }
    };
    //#endregion HANDLER =====/

    //#region UI ========/
    const placementMapped = useMemo(() => {
      if (permitObj?.Placements && permitObj?.Placements.length > 0) {
        return uniqBy(
          permitObj?.Placements.map((placement: Placement) => ({
            Keyword_ID: placement?.Placement_KWD,
            Name: placement?.Placement_Name,
          })),
          nameOfKeyword("Keyword_ID")
        );
      }
      return [];
    }, [permitObj?.Placements]);

    const purposeMapped = useMemo(() => {
      if (permitObj?.Purposes && permitObj?.Purposes.length > 0) {
        return uniqBy(
          permitObj?.Purposes.map((purpose: Purpose) => ({
            Keyword_ID: purpose?.Purpose_KWD,
            Name: purpose?.Purpose_Name,
          })),
          nameOfKeyword("Keyword_ID")
        );
      }
      return [];
    }, [permitObj?.Purposes]);

    const isShowPermitEventName = useMemo(
      () =>
        isUsePermitEventName && permitObj?.PermitType?.Flag_UsePermitEventName,
      [permitObj?.PermitType?.Flag_UsePermitEventName, isUsePermitEventName]
    );
    //#endregion UI =====/

    const handleValidateFromDate = useCallback(
      (value: Date) => {
        return (
          (isShowPermitEffectiveDates && requiredValidator(value)) ||
          validatorDateValueWithMinMax(
            value,
            null,
            valueGetter(getNameOf("Date_EffectiveTo"))
          )
        );
      },
      [valueGetter, isShowPermitEffectiveDates]
    );

    const handleValidateToDate = useCallback(
      (value: Date) => {
        return (
          (isShowPermitEffectiveDates && requiredValidator(value)) ||
          validatorDateValueWithMinMax(
            value,
            valueGetter(getNameOf("Date_EffectiveFrom")),
            null
          )
        );
      },
      [valueGetter, isShowPermitEffectiveDates]
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Description" />
            <Field
              name={getNameOf("Description")}
              rows={4}
              component={CCTextArea}
              placeholder="Description"
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title="Effective from"
              isMandatory={isShowPermitEffectiveDates}
            />
            <Field
              name={getNameOf("Date_EffectiveFrom")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              readOnly={!isShowPermitEffectiveDates}
              validator={handleValidateFromDate}
              max={
                getValue("Date_EffectiveTo")
                  ? new Date(getValue("Date_EffectiveTo"))
                  : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title="Effective to"
              isMandatory={isShowPermitEffectiveDates}
            />
            <Field
              name={getNameOf("Date_EffectiveTo")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              readOnly={!isShowPermitEffectiveDates}
              validator={handleValidateToDate}
              min={
                getValue("Date_EffectiveFrom")
                  ? new Date(getValue("Date_EffectiveFrom"))
                  : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Officer" isMandatory />
            <Field
              component={InputPickerSearch}
              name={getNameOf("Officer")}
              nameDisplay="DisplayName"
              value={getValue("Officer")}
              options={permitOptionOfficer}
              onChange={pickOfficerHandler}
              validator={requiredValidator}
              placeholder="Select officer"
            />
          </div>
          <div className="cc-field">
            <CCLabel title="File number" />
            <Field
              component={CCInput}
              name={getNameOf("FileNumber")}
              placeholder="File number"
            />
          </div>
        </div>
        {isFieldVisible(uiControlStore?.DivDimensionDetails?.DisplayStatus) && (
          <div className="cc-form-cols-3">
            {isFieldVisible(uiControlStore?.DivDimension1?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel1 ?? "Dimension 1"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_1")}
                />
              </div>
            )}
            {isFieldVisible(uiControlStore?.DivDimension2?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel2 ?? "Dimension 2"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_2")}
                />
              </div>
            )}
            {isFieldVisible(uiControlStore?.DivDimension3?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel3 ?? "Dimension 3"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_3")}
                />
              </div>
            )}
            {isFieldVisible(uiControlStore?.DivDimension4?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel4 ?? "Dimension 4"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_4")}
                />
              </div>
            )}
            {isFieldVisible(uiControlStore?.DivDimension5?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel5 ?? "Dimension 5"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_5")}
                />
              </div>
            )}
            {isFieldVisible(uiControlStore?.DivDimension6?.DisplayStatus) && (
              <div className="cc-field">
                <CCLabel
                  title={
                    permitObj?.PermitType?.DimensionLabel6 ?? "Dimension 6"
                  }
                />
                <Field
                  component={CCNumericTextBox}
                  name={getNameOf("DimensionValue_6")}
                />
              </div>
            )}
          </div>
        )}

        {isFieldVisible(uiControlStore?.DivInsuranceDetails?.DisplayStatus) && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Insurance company" />
              <Field
                component={CCDropDownList}
                name={getNameOf("InsuranceCompany_KWD")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={permitLOVsStore?.InsuranceCompany ?? []}
                isKeyValueDropdown
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Policy number" />
              <Field
                component={CCInput}
                name={getNameOf("InsurancePolicyNumber")}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Policy issue date" />
              <Field
                name={getNameOf("Date_InsuranceIssued")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Policy expires" />
              <Field
                name={getNameOf("Date_InsuranceExpires")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
        )}

        <div className="cc-form-cols-3">
          {isFieldVisible(uiControlStore?.DivPlacement?.DisplayStatus) && (
            <div className="cc-field">
              <CCLabel title="Placement" />
              <Field
                component={InputPickerSearch}
                name={getNameOf("Placements")}
                nameDisplayMerge={nameOfKeyword("Name")}
                value={placementMapped}
                options={permitOptionPlacement}
                onChange={pickPlacementHandler}
              />
            </div>
          )}

          {isFieldVisible(uiControlStore?.DivPurpose?.DisplayStatus) && (
            <div className="cc-field">
              <CCLabel title="Purpose of" />
              <Field
                component={InputPickerSearch}
                name={getNameOf("Purposes")}
                nameDisplayMerge={nameOfKeyword("Name")}
                value={purposeMapped}
                options={permitOptionPurpose}
                onChange={pickPurposeHandler}
              />
            </div>
          )}

          {isFieldVisible(uiControlStore?.DivTypeOf?.DisplayStatus) && (
            <div className="cc-field">
              <CCLabel title={permitObj?.PermitType?.TypeOfName} />
              <Field
                component={CCInput}
                name={getNameOf("TypeOfDescription")}
              />
            </div>
          )}

          {isShowPermitEventName && (
            <div className="cc-field">
              <CCLabel title="Event name" />
              <Field
                component={CCInput}
                name={getNameOf("UsePermitEventName")}
              />
            </div>
          )}

          {isFieldVisible(
            uiControlStore?.DivRegistrationNumber?.DisplayStatus
          ) && (
            <div className="cc-field">
              <CCLabel title="Registration number" />
              <Field
                component={CCInput}
                name={getNameOf("RegistrationNumber")}
              />
            </div>
          )}
        </div>
        {isFieldVisible(uiControlStore?.DivTime?.DisplayStatus) && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Time from" />
              <Field
                name={getNameOf("TimeFrom")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Time to" />
              <Field
                name={getNameOf("TimeTo")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
        )}
        <div className="cc-form-cols-3">
          {isFieldVisible(uiControlStore?.DivMake?.DisplayStatus) && (
            <div className="cc-field">
              <CCLabel title="Make" />
              <Field
                component={CCDropDownList}
                name={getNameOf("PermitMake_KWD")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={permitLOVsStore?.Make ?? []}
                isKeyValueDropdown
              />
            </div>
          )}

          {isFieldVisible(uiControlStore?.DivModel?.DisplayStatus) && (
            <div className="cc-field">
              <CCLabel title="Model" />
              <Field
                component={CCDropDownList}
                name={getNameOf("PermitModel_KWD")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={permitLOVsStore?.Model ?? []}
                isKeyValueDropdown
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
