import {
  Contact_Relation,
  DynamicAssessment,
} from "@app/core/inspections/[id]/model";
import { ElementControl } from "@app/products/crms/[id]/model";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { TErrorComponent } from "@common/hooks/useCallApiHandleLoadFailed";
import { Address } from "@common/input-pickers/address/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";

export enum Permit_Status {
  Unspecified = 0,
  NewApplication = 1,
  PermitIssued = 2,
  PermitRefused = 3,
  PermitDeactivated = 4,
  PermitExpired = 5,
  PermitLodged = 6,
  PermitWithdrawn = 7,
  PermitAmended = 8,
  PermitApproved = 9,
  PermitCancelled = 10,
  PermitOnHold = 11,
  PermitRevoked = 12,
  PermitFinalApproved = 13,
  Draft = 14,
  NewTender = 15,
  Advertising = 16,
  ReadyForAssessment = 17,
  AssessmentComplete = 19,
  Endorsed = 19,
  AcknowledgmentSent = 20,
  AcknowledgmentReceived = 21,
  TenderSuccessful = 22,
  InvoiceGenerated = 23,
}

export interface Purpose {
  Permit_Purpose_ID: number;
  Permit_ID: number;
  Purpose_KWD: number;
  Purpose_Name: string;
}

export interface Placement {
  Permit_Placement_ID: number;
  Permit_ID: number;
  Placement_KWD: number;
  Placement_Name: string;
}

export enum PermitUpdateTriggers {
  UpdateContact,
  UpdateAddress,
  UpdatePermitType,
  UpdatePurposes,
  UpdatePlacements,
  UpdateSupervisor,
  UpdatePreferredContact,
  UpdateAdditionalContact1,
  UpdateAdditionalContact2,
  UpdateAdditionalContact3,
  UpdateAdditionalContact4,
  UpdatePermitDetails,
}

export enum LLP_CONFIGURATIONMODE {
  SystemInitialise = 0,
  LOCAL_GOV = 1,
  STATE_AUTH = 2,
}

export enum Invoice_Terms {
  Unspecified = 0,
  Immediately = 1,
  After30days = 2,
}

export interface Holding {
  Holding_RowID: number;
  Holding_ID: number;
  RefNo: string;
  Name: string;
  ReserverNos: string;
  Address: string;
  PIC: string;
  Lots: string;
}

export interface Livestock {
  LiveStock_RowID: number;
  LiveStock_ID: number;
  Species_KWD: number;
  Breed_KWD: number;
  Gender_KWD: number;
  GSC_KWD: number;
  DSE_KWD: number;
  Age_KWD: number;
  MaxQty: number;
  IsTravalingSlow: boolean;
}

export interface PermitDetails extends DTOBaseClass_Standard {
  Permit_ID: number;
  PermitDetail_ID: number;
  OriginHoldingRefNo: string;
  OriginHoldingName: string;
  OriginHoldingAddress: string;
  OriginPIC: string;
  OriginLots: string;
  TSRHoldingRefNo: string;
  TSRHoldingName: string;
  TSRHoldingAddress: string;
  TSRPIC: string;
  TSRArea: string;
  TSRLots: string;
  TSRHectares: string;
  Is_TransitPropertyOwnerOccupied: boolean;
  Is_TransitTSRUsed: boolean;
  TransitHoldings: Holding[];
  Is_DestinationPropertyOwnerOccupied: boolean;
  DestinationHoldingRefNo: string;
  DestinationHoldingName: string;
  DestinationHoldingAddress: string;
  DestinationPIC: string;
  DestinationLots: string;
  RouteShireRoadNos: string;
  RouteShireRoadNames: string;
  RouteTravelDistance: string;
  Is_SlowTravelRequested: boolean;
  SlowTravelDays: number | null;
  SlowTravelDetails: string;
  Livestock: Livestock[];
  TotalLivestockQty: number;
  TotalLargeStock: number | null;
  TotalSmallStock: number | null;
  TotalHorsesAndCamels: number | null;
  FeasibilityQuestionTabs: DynamicAssessment[];
  Date_AOApproverSignOff: Date | null;
  Comment_AOApproverSignOff: string;
  Date_BIOApproverSignOff: Date | null;
  Comment_BIOApproverSignOff: string;
}

export enum Permit_SubStatus {
  Unspecified = 0,
  Awaiting_AO_Approval = 1,
  Awaiting_BIO_Approval = 2,
  Ready_To_Invoice = 3,
  Invoice_30_days = 4,
  Invoiced = 5,
  Validated = 6,
  FurtherInformationRequested = 7,
  AORejected = 8,
  BIORejected = 9,
  AOBIORejected = 10,
  ReadyToIssues = 11,
  WaitingForPaymentVerification = 12,
  Awaiting_AOBIO_Approval = 13,
  DueForAnnualInvoicing = 14,
  AnnualInvoiceWaitingForPaymentVerification = 15,
}

export interface Permit extends DTOBaseClass_Standard {
  Permit_ID: number;
  PermitType_ID: number;
  PermitType: PermitType;
  Permit_Number: string;
  AdditionalAddresses: Address[];
  Status_ENUM: Permit_Status;
  Status_Name: string;
  DueStatus: string;
  Description: string;
  TypeOfDescription: string;
  UsePermitEventName: string;
  DebtorNumber: string;
  FileNumber: string;
  ReferenceNumber: string;
  DimensionValue_1: number | null;
  DimensionValue_2: number | null;
  DimensionValue_3: number | null;
  DimensionValue_4: number | null;
  DimensionValue_5: number | null;
  DimensionValue_6: number | null;
  Flag_IndemnityReceived: boolean | null;
  Contact: Contact_Relation;
  Contact_RID: number | null;
  Address: Address;
  Address_ID: number | null;
  InsurancePolicyNumber: string;
  InsuranceAmount: number | null;
  InsuranceCompany_KWD: number | null;
  InsuranceCompany_Name: string;
  Flag_RenewalNoticeSent: boolean | null;
  Flag_RenewalFeeCreated: boolean | null;
  Date_Recorded: Date | null;
  Date_Lodged: Date | null;
  Date_EffectiveFrom: Date | null;
  Date_EffectiveTo: Date | null;
  Date_InsuranceExpires: Date | null;
  Date_InsuranceIssued: Date | null;
  Date_PermitIssued: Date | null;
  Purposes: Purpose[];
  Purpose_Namelist: string[];
  Purpose_Display: string;
  Placements: Placement[];
  Placement_Namelist: string[];
  Placement_Display: string;
  RegistrationNumber: string;
  TimeFrom: Date | null;
  TimeTo: Date | null;
  PermitMake: string;
  PermitModel: string;
  PermitModel_KWD: number | null;
  PermitModel_Name: string;
  PermitMake_KWD: number | null;
  PermitMake_Name: string;
  PermitArea_KWD: number | null;
  Area: string;
  SaveTriggers: PermitUpdateTriggers[];
  _SaveTriggers: PermitUpdateTriggers[];
  ConfigurationMode: LLP_CONFIGURATIONMODE | null;
  Officer: SiteUser;
  Officer_ID: number | null;
  AOApprover: SiteUser;
  AOApprover_ID: number | null;
  BIOApprover: SiteUser;
  BIOApprover_ID: number | null;
  Supervisor: Contact_Relation;
  Supervisor_RID: number | null;
  PreferredContact: Contact_Relation;
  PreferredContact_RID: number | null;
  CRMS_EventID: number | null;
  AdditionalContact1: Contact_Relation;
  AdditionalContact1_RID: number | null;
  AdditionalContact2: Contact_Relation;
  AdditionalContact2_RID: number | null;
  AdditionalContact3: Contact_Relation;
  AdditionalContact3_RID: number | null;
  AdditionalContact4: Contact_Relation;
  AdditionalContact4_RID: number | null;
  InvoiceTerm_ENUM: Invoice_Terms | null;
  InvoiceTerm_Name: string;
  PermitDetails: PermitDetails;
  IsApprovedByAOApprover: boolean | null;
  IsApprovedByBIOApprover: boolean | null;
  TotalDays: number | null;
  SubStatus_ENUM: Permit_SubStatus | null;
  SubStatus_Name: string;
  MAPTerm_KWD: number | null;
  MAPTerm: string;
  TenderAmount: number | null;
  TotalPermitUsedDays: number | null;
  RejectionReason: string;
  Days_DueToExpir: number | null;
  Days_DueForAnnualInvoice: number | null;
  Date_NextAnnualInvoice: Date | null;
}

export enum Svc_FormAction_PermitRegister {
  SystemInitialise,
  Form_PickContact,
  Form_PickSiteAddress,
  Form_PickSupervisor,
  Form_PickPreferredContact,
  Form_PickOfficer,
  Form_PickPermitType,
  Form_PickPlacement,
  Form_PickPurpose,
  Form_PickFileNumber,
}

export interface PermitRegisterHandlerRequest {
  FormAction: Svc_FormAction_PermitRegister;
  Permit: Permit;
  PermitRegisterArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface IHandlerEventInitialData {
  permitRegisterHandlerRequest: PermitRegisterHandlerRequest;
  errorMsg: string;
}

export interface PermitRegisterUIControl {
  LitContact: ElementControl;
  LitAddress: ElementControl;
  LitSupervisor: ElementControl;
  LitPreferredContact: ElementControl;
  LitDetails: ElementControl;
  LitOwners: ElementControl;
  LitContactHomePhone: ElementControl;
  LitContactWorkPhone: ElementControl;
  LitContactMobilePhone: ElementControl;
  LitContactEmail: ElementControl;
  LitContactContact: ElementControl;
  LitContactAddress: ElementControl;
  LitContactPostalAddress: ElementControl;
  LitPermitType: ElementControl;
  DivDimensionDetails: ElementControl;
  DivInsuranceDetails: ElementControl;
  DivDimension1: ElementControl;
  DivDimension2: ElementControl;
  DivDimension3: ElementControl;
  DivDimension4: ElementControl;
  DivDimension5: ElementControl;
  DivDimension6: ElementControl;
  DivPlacement: ElementControl;
  DivPurpose: ElementControl;
  DivTypeOf: ElementControl;
  DivRegistrationNumber: ElementControl;
  DivTime: ElementControl;
  DivMake: ElementControl;
  DivModel: ElementControl;
}

export interface PermitRegisterHandlerResponse {
  Permit: Permit;
  UIControl: PermitRegisterUIControl;
}

export interface Svc_Permit {
  Permit: Permit;
  OSFee: number | null;
  PermitBalance: number | null;
}

export interface ApiPermitTransferData {
  notifications: string[];
  permitFormData: Svc_Permit;
}

export interface PermitRegisterLovs {
  InsuranceCompany: IKeyValuePacket[];
  Make: IKeyValuePacket[];
  Model: IKeyValuePacket[];
  AreaofCouncil: IKeyValuePacket[];
}

//#region TYPE: PERMIT FORM ========/
type TLoadPermitById = IIdentityPacket<Svc_Permit>;
export type TApiLoadPermitByIdResponse = APIResponse<TLoadPermitById>;
export type TApiLoadPermitById = TApiLoadPermitByIdResponse | APIResponseError;

type TLoadPermitLovs = IIdentityPacket<PermitRegisterLovs>;
export type TApiLoadPermitLovsResponse = APIResponse<TLoadPermitLovs>;
export type TApiLoadPermitLovs = TApiLoadPermitLovsResponse | APIResponseError;

export type TApiIsSuperAdminResponse = APIResponse<boolean>;
export type TApiIsSuperAdmin = TApiIsSuperAdminResponse | APIResponseError;

export type TApiPermitResponse = [
  TApiLoadPermitByIdResponse,
  TApiLoadPermitLovsResponse,
  TApiIsSuperAdminResponse
];

export interface IParentPermitSection {
  animalID?: number;
}

type TPermitHandler = IIdentityPacket<PermitRegisterHandlerResponse>;
export type TApiPermitHandlerResponse = APIResponse<TPermitHandler>;
export type TApiPermitHandler = TApiPermitHandlerResponse | APIResponseError;

export type TApiIdentityResponse = APIResponse<IdentityPacket>;
export type TApiIdentity = TApiIdentityResponse | APIResponseError;

export interface IManagePermit {
  errorGetPermitComponent: TErrorComponent;
  titleManagePage: string;
  id: string;
}
//#endregion TYPE: PERMIT FORM =====/

//#region TYPE: PERMIT FORM WORKFLOW ========/
export interface Svc_Permit_Workflow_ProvideReason {
  Permit: Permit;
  ProvideReason: ProvideReason;
}

export interface Svc_Permit_Workflow_DateFromDateTo {
  Permit: Permit;
  DateFromDateTo: DateFromDateTo | null;
}
//#endregion TYPE: PERMIT FORM  WORKFLOW=====/
